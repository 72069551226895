import React, {useState, useEffect, useRef, memo} from 'react'
import styled from 'styled-components'

import startIcon from '../assets/logos/logo_plain.png'
import trashIcon from '../assets/icons/trashcan_icon.png'
import pcIcon from '../assets/icons/pc_icon.png'
import shutdownIcon from '../assets/icons/shutdown_icon.png'
import picturesIcon from '../assets/icons/pictures_icon.png'
// import documentsIcon from '../assets/icons/documents_icon.png'
import cmdIcon from '../assets/icons/prompt_icon.png'
import blogIcon from '../assets/icons/blog_icon.png'

// Dark mode
import dayIcon from '../assets/icons/modes/light.png'
import nightIcon from '../assets/icons/modes/dark.png'

// Task icon


  //Start of styling
  const TaskbarDiv = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    background: var(--color-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 299;
  `
  
  const StartButton = styled.button`
    position: relative;
    height: 80%;
    padding-right: 10px;
    margin: auto 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
    border: solid #c8c8c8 2px;
    border-color: ${props => props.StartMenuOpen?"#686868":"#c8c8c8 #686868 #686868 #c8c8c8"};
    box-shadow: ${props => props.StartMenuOpen?"inset 0px 0px 5px #0c0c0c":""};
    background: ${props => props.StartMenuOpen?'#dadada':''};
    cursor: pointer;
    color: black;
  
    img{
      margin: 10px;
      height: 30px;
      width: 30px;
    }
    
    .active{
      box-shadow: inset 1px 1px 5px #686868;
    }
    
  `
  
  const Block = styled.div`
    position: relative;
    height: 80%;
    width: 10px;
    background: var(--color-grey);
    border: 2px solid #929292;
    border-color: #f0f0f0 #4d4d4d #4d4d4d white;
  
    &.addMargin{
      margin-right: 30px;
    }

    @media screen and (max-width:500px){
      display: none;
    }
  `
  
  const BlockIcons = styled.button`
    position: relative;
    height: 80%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
  
    img{
      margin: 5px;
      height: 30px;
      width: 30px;
    }

    @media screen and (max-width:500px){
      display: none;
    }
  `
  
  
  const StartMenu = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    background: var(--color-grey);
    height: auto;
    width: 280px;
    top: 0;
    transform: translateY(-100%);
    left: 0;
  `
  
  const BannerStart = styled.div`
    position: relative;
    height: 350px;
    width: 50px;
    margin: 5px;
    background: linear-gradient(180deg, #0B21E3 0%, #000000 75.63%);
    span{
      position: absolute;
      bottom: 10px;
      text-align: start;
      left:50%;
      width: 100%;
      transform: translateX(-50%) rotate(-90deg);
      white-space: nowrap;
      font-size: 1.5rem;
      font-family: 'Inter';
      color: whitesmoke;
    }
  `
  
  const ContainerMenu = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  `
  
  const DividerItem = styled.div`
    position: relative;
    width: 96%;
    height: 2px;
    background: #808080;
  
  `
  
  const MenuItem = styled.button`
    position: relative;
    width: 90%;
    background: none;
    border: none;
    font-size: 1rem;
    margin: 5px auto;
    cursor: pointer;
    figure{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      figcaption{
        color: black;
      }
      img{
          margin: 5px 20px 5px 5px;
          height: 50px;
          width: 50px;
      }
    }
          
  `
    
  const ShutdownItem = styled.button`
    position: relative;
    width: 90%;
    background: none;
    border: none;
    font-size: 1rem;
    margin: 5px auto;
    cursor: pointer;
    transition: 100ms;

    figure{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      figcaption{
        color: black;
      }
      img{
          margin: 5px 20px 5px 5px;
          height: 50px;
          width: 50px;
      }
    }
  
    &:hover{
      opacity: 0;
    }
          
  `

  const TasksArea = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  `
  
  const TaskBarItemContainer = styled.div`
    position: relative;
    display: flex
    width: fit-content;
    height: fit-content;
    height: 70%;
    min-width: 20%;
    margin: auto 10px;
  `

  const TaskbarItem = styled.button`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
    border: solid #c8c8c8 1.5px;
    border-color: #c8c8c8 #686868 #686868 #c8c8c8;
    box-shadow: 1px 1px 1px #686868;
    cursor: pointer;
    padding-right: 45px;
    figure{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      figcaption{
        margin: auto;
        color: black;
      }
      picture{
        img{
          margin: auto 5px;
          height: 30px;
          width: 30px;
        }
      }
    }

    @media screen and (max-width:500px){
      padding-right: 25px;
      figcaption{
        display: none;
      }
    }
  `
  const CloseTaskBtn = styled.button`
    content: "x";
    color: black;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    margin: auto 5px auto auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border: solid #c8c8c8 1.5px;
    border-color: #c8c8c8 #686868 #686868 #c8c8c8;
    box-shadow: 1px 1px 1px #686868;
    cursor: pointer;
    pointer-events: all;

  `

  const StatusArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: auto;
    height: 75%;
    justify-content: start;
    align-items: center;
    border: solid #686868 1px;
    border-color: #686868 #f1f1f1 #f1f1f1 #686868;
    box-shadow: inset 0 0 1px #686868;
    margin:  auto 10px auto auto;
    white-space: nowrap;
    padding-right: 10px;
  `

  const ModeButton = styled.button`
    position: relative;
    height: 95%;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    img{
      height: 100%;
    }
  `

const Taskbar = ({ mode, setMode, taskStack, activateTask, deactivateTask, aStack, setLastClickTaskBar }) => {
  const [StartMenuOpen, setStartMenuOpen] = useState(false)
  const locale = 'en';
  const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
      const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });

  const toggleMode = () => setMode(!mode)
  
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setStartMenuOpen(false)
        }
      };

      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, []);  

  return (
    <TaskbarDiv>
      { StartMenuOpen &&
      <StartMenu ref={ref}>
        <BannerStart><span><b>Doors</b> 2000 <b>Professional</b></span></BannerStart>
        <ContainerMenu>
          <MenuItem onClick={()=>activateTask(1,'')}>
            <figure><picture><img src={startIcon} alt="Logo" /></picture><figcaption>Getting Started</figcaption></figure> 
          </MenuItem>
          <DividerItem/>
          <MenuItem onClick={()=>activateTask(2,'')}>
            <figure><picture><img src={pcIcon} alt="Logo" /></picture><figcaption>My Computer</figcaption></figure>  
          </MenuItem>
          <MenuItem onClick={() => window.open('https://ai.franklinbarto.com',"_blank")}>
            <figure><picture><img src={blogIcon} alt="Logo" /></picture><figcaption>Data Blog</figcaption></figure>
          </MenuItem>
          <MenuItem onClick={()=>activateTask(6,'')}>
            <figure><picture><img src={picturesIcon} alt="Logo" /></picture><figcaption>Gallery</figcaption></figure>
          </MenuItem>
          <MenuItem onClick={()=>activateTask(5,'')}>
            <figure><picture> <img src={trashIcon} alt="Logo" /></picture><figcaption>Recycle Bin</figcaption></figure>
          </MenuItem>
          <DividerItem/>
          <ShutdownItem>
            <figure><picture><img src={shutdownIcon} alt="Logo" /></picture><figcaption>Shutdown</figcaption></figure>
          </ShutdownItem>
        </ContainerMenu>
      </StartMenu>
      }
      <StartButton StartMenuOpen={StartMenuOpen} status={StartMenuOpen?"active":""} onClick={()=>(setStartMenuOpen(curr=>!curr))}><img src={startIcon} alt="Logo" />Start</StartButton>
      <Block></Block>
      <BlockIcons  onClick={()=>activateTask(2,'')}><img src={pcIcon} alt="icon" /></BlockIcons>
      <BlockIcons  onClick={()=>activateTask(5,'')}><img src={trashIcon} alt="icon" /></BlockIcons>
      <BlockIcons  onClick={()=>activateTask(3,'')}><img src={cmdIcon} alt="icon" /></BlockIcons>
      <Block className='addMargin'></Block>

      {/* Taskbar area */}
      <TasksArea>
        {taskStack && taskStack.map(task=>{
          if(task && aStack.includes(task.id)){
            return(
              <TaskBarItemContainer>
                <TaskbarItem onClick={()=>activateTask(task.id,'')} title={task.title}>{task.icon && <figure><picture><img src={task.icon[Object.keys(task.icon)[0]]} alt="Logo" /></picture><figcaption>{task.title}</figcaption></figure> }</TaskbarItem>  
                <CloseTaskBtn onClick={()=>deactivateTask(task.id)}>X</CloseTaskBtn> 
              </TaskBarItemContainer>
              )
            }
            return ''
        })
        }
      </TasksArea>

      <StatusArea>
        <ModeButton onClick={toggleMode}>
          {mode && <img src={dayIcon} alt="Day time icon" /> }
          {!mode &&  <img src={nightIcon} alt="Night time icon" /> }
        </ModeButton>
        <span>{time}</span>
      </StatusArea>
    </TaskbarDiv>
  )
}

export default memo(Taskbar)