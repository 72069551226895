import React from 'react'
import styled from 'styled-components'

// import icons
import computerIcon from '../assets/icons/pc_icon.png'
import trashIcon from '../assets/icons/trashcan_icon.png'

import startIcon from '../assets/logos/logo_plain.png'
import internetIcon from '../assets/icons/internet_icon.png'
import internetPDFIcon from '../assets/icons/pdf_explorer_icon.png'
import emailIcon from '../assets/icons/email_icon.png'

import blogIcon from '../assets/icons/blog_icon.png'
import { Link } from 'gatsby'

const Container = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    height: 80vh;
    /* background-color: red; */
    z-index: 1;
    `
const Header = styled.header`
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
`
const DesktopItem = styled.button`
    position: relative;
    height: 85px;
    width: fit-content;
    margin: 10px 15px;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    cursor: pointer;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;

    figure{
      picture img{
        width: 60px;
        height: 60px;
        margin: 5px 10px;
      }
      figcaption{
        color: whitesmoke;
        white-space: nowrap;
        font-size: large;
      }
    }
    @media screen and (max-width:500px){
      margin: 5px;
      figure{
        picture img{
          width: 40px;
          height: 40px;
        }
        figcaption{
          font-size: small;
        }
      }
    }
    `

const DesktopIcon = ({activateTask}) => {
  return (
    <Container>
        <Header>
          <DesktopItem onClick={()=>activateTask(1,'')}>
            <figure>
              <picture><img src={startIcon} alt="title" /></picture>
              <figcaption>Start Page</figcaption>
            </figure>
          </DesktopItem>
          <DesktopItem onClick={()=>activateTask(4,'')}>
            <figure>
              <picture><img src={internetPDFIcon} alt="title" /></picture>
              <figcaption>My Resume.pdf</figcaption>
            </figure>
          </DesktopItem>
          <DesktopItem onClick={()=>activateTask(0,'')}>
            <figure>
              <picture><img src={internetIcon} alt="title" /></picture>
              <figcaption>My Portfolio.html</figcaption>
            </figure>
          </DesktopItem>
          <DesktopItem onClick={()=>activateTask(8,'')}>
            <figure>
              <picture><img src={emailIcon} alt="Contacts" /></picture>
              <figcaption>My Contacts</figcaption>
            </figure>
          </DesktopItem>
          <DesktopItem onClick={() => window.open('https://ai.franklinbarto.com',"_blank")}>
            <figure>
              <picture><img src={blogIcon} alt="Blog Icon" /></picture>
              <figcaption>Data Science Blog</figcaption>
            </figure>
          </DesktopItem>
        </Header>
        
        <DesktopItem onClick={()=>activateTask(2,'')}>
            <figure>
              <picture><img src={computerIcon} alt="title" /></picture>
              <figcaption>My Computer</figcaption>
            </figure>
        </DesktopItem>
        <DesktopItem onClick={()=>activateTask(5,'')}>
            <figure>
              <picture><img src={trashIcon} alt="title" /></picture>
              <figcaption>Recycle Bin</figcaption>
            </figure>
        </DesktopItem>
    </Container>
  )
}

export default DesktopIcon
