import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import internetPDFIcon from '../assets/icons/pdf_explorer_icon.png'
import minIcon from '../assets/icons/minimize_icon.png'
import maxIcon from '../assets/icons/maximize_icon.png'
import closeIcon from '../assets/icons/close_icon.png'
import backIcon from '../assets/icons/back_icon.png'
import logo from '../assets/logos/logo_plain.png'
import frank from '../assets/artboard/frank_2.jpg'

    const ContainerDiv = styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        ${props=>props.minimize?'width: 80vw;':'width: 100%;'}
        ${props=>props.minimize?'height: 85vh;':'height: 100%;'}
        background: #D9D9D9;
        z-index: 100;
        border: 1px solid black;
        padding: 5px;
        pointer-events: all;

        @media screen and (max-width:700px){
            ${props=>props.minimize?'width: 95vw;':'width: 100%;'}
            ${props=>props.minimize?'height: 85vh;':'height: 100%;'}
        }
    `

    const Header = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        background: linear-gradient(89.99deg, rgba(10, 57, 221, 0.86) 0.7%, #CCCCCC 99.26%);
        padding: 5px 0 5px 15px;
        justify-content: end;
        align-items: center;
        pointer-events: all;
        
        span.header{
            pointer-events: none;
            color: black;
            font-size: 1.2rem;
            margin: 0 auto 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            pointer-events: none;
            color: whitesmoke;
            img{
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }

        button{
            margin: auto 10px auto 1px;
            background: #D9D9D9;
            border: 1px solid black;
            padding: 5px;
            width: 25px;
            height: 25px;
            pointer-events: all;
            cursor: pointer; 
            img{
                width: 100%;
                height: 100%;
            }
        }
    `

    const SubHeader = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        border: 1px solid black;
        background-color: #D9D9D9;
        align-items: center;
        span.subHeader{
        }
    `

    const Divider = styled.div`
        position: relative;
        width: 3px;
        height: 90%;
        background-color: #A9A9A9;
        margin: auto 5px;
    `

    const LinkItem = styled.button`
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        color: gray;
        img{
            height: 15px;
            margin-right: 5px;
        }
        &.back{
            color: black;
        }
    `

    const LogoDiv = styled.div`
        position: relative;
        margin: auto 0 auto auto;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100px;
        img{
            height: 70%;
        }
    
    `

    const Address = styled.div`
        position: relative;
        margin: auto auto auto 15px;
        height: 90%;
        width: 60%;
        background: white;
        display: flex;
        flex-direction: row;
        border: 1px solid black;
        align-items: center;
        
        img{
            height: 80%;
            margin-right: 5px;
            margin-left: 5px;
        }

        span.address{
            margin: auto auto auto 2px;
        }

        @media screen and (max-width:700px){
            font-size: small;
        }
    `

    const Content = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        margin-top: 2px;
        border: 1px solid black;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 10px;
        pointer-events: all;
    `

    const Resume = styled.div`
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
	    padding: 40px;
        background: #fff;
        pointer-events: all;
        z-index: 10;

        @media screen and (max-width:700px){
            padding: 10px;
        }
    `

    const ResumeHeader = styled.div`
        position: relative;
        margin: 50px auto;
        width: 100%;
        border-bottom: 1px Solid grey;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        h2{
            font-size: medium;
        }
        h2:nth-child(1){
            margin: auto auto auto 10px;
        }
        h2:nth-child(2){
            margin: auto 10px auto auto;
        }

        @media screen and (max-width:700px){
            h2{
                font-size: x-small;
            }
        }
    `

    const Section1 = styled.div`
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: row;
	    margin-bottom: 50px;

        img{
	        object-fit: cover;
            width: 40%;
            height: 100%;
	        margin: 0 auto;
        }

        div{
	        position: relative;
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            text-align: right;
            h2:nth-child(1){
                font-size: 3rem;
                margin-right: 10px;
            }
            h2:nth-child(2){
                font-size: 6rem;
            }
            p{
                color: #1e1e1e;
                width: 75%;
                margin: 20px 0 auto auto;
            }
            a{
                margin: auto 20px 10px 0;
                pointer-events: all;
                cursor: pointer;
	        }
        }

        @media screen and (max-width:700px){
            height: 250px;
            div{
                h2:nth-child(1){
                    font-size: 1.5rem;
                    margin-right: 10px;
                }
                h2:nth-child(2){
                    font-size: 1.2rem;
                }
                p{
                    font-size: x-small;
                }
                span,a{
                    font-size: x-small;
                }
            }

            img{
                width: 50%;
            }
        }

    `

    const Section2 = styled.div`
        position: relative;
        display: flex;
        flex-direction: row;
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
	    width: 100%;

        div:nth-child(1){
            display: flex;
            flex-direction: column;
            width: 65%;
	        padding: 20px;

            h2{
                font-size: large;
                font-weight: normal;
                margin-top: 25px;
            }
            li{
                color: #1e1e1e;
                margin-top: 10px;
                margin-left: 30px;
            }
        }

        div:nth-child(2){
            display: flex;
            flex-direction: column;
            width: 35%;
            padding: 20px;
            border-left: 1px solid gray;
            h2{
                margin: 25px auto 10px 10px;
            }
            span{
                font-weight: bold;
                margin-top: 10px;
            }
            p{
                color: #1e1e1e;
                width: 75%;
            }
	    }
        @media screen and (max-width:700px){
            div:nth-child(1){
	            padding: 5px;
                span,h2{
                    font-size: x-small;
                }
                li{
                    font-size: x-small;
                }
            }
            div:nth-child(2){
	            padding: 5px;
                h2{
                    font-size: small;
                }
                span{
                    font-size: x-small;
                }
                p{
                    font-size: x-small;
                }
            }
        }
    `

     const ResumeFooter = styled.div`
        position: relative;
        width: 100%;
        margin-bottom: 50px;
	    margin-top: 10px;
        display: flex;
        flex-direction: row;
        h2{
            font-size: medium;
        }
        span:nth-child(1){
                margin: auto auto auto 10px;
        }
	    span:nth-child(2){
                margin: auto 10px auto auto;
        }
        @media screen and (max-width:700px){
            font-size: x-small;
        }
    `

    const ContentFooter = styled.div`
        position: relative;
        width: 100%;
        height: 35px;
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        div{
            height: 100%;
            width: 60%;
            border: 1px solid black;
            display: flex;
            align-items: center;
            padding-left: 5px;
            &:nth-child(2){
                width: 39%;
                margin: 0 0 0 auto;
                img{
                    height: 80%;
                    margin: auto 5px;
                }
            }
        }
    `

const ResumeExplorer = ({ id, pushZStack, deactivateTask, backPress, appendToMinimize}) => {

    const [moveWindow, toggleWindow] = useState()
    const [offset,updateOffset] = useState({x:0,y:0})
    const mainWindowRef = useRef()
    const [minimize,toggleMinimize] = useState(true)

    const toggleWindowSize = (prev)=>{
        toggleMinimize(!prev)
    }
   
    //offset works
    const onClickOffset = (e)=>{
        const mainWindow = mainWindowRef.current
        var x,y
        if(e.clientX>=0||e.clientY>=0){
            x = -(mainWindow.getBoundingClientRect().x - e.clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.clientY)
            
        }else{
            x = -(mainWindow.getBoundingClientRect().x - e.touches[0].clientX)
            y = -(mainWindow.getBoundingClientRect().y - e.touches[0].clientY)
        }
        updateOffset({x,y})
    }


    var handleWindowMove = (e)=>{
        const mainWindow = mainWindowRef.current
        var newPosX
        var newPosY 

        if(e.clientX>=0||e.clientY>=0){
            newPosX = e.clientX - offset.x
            newPosY = e.clientY - offset.y
        }else{
            newPosX = e.touches[0].clientX - offset.x
            newPosY = e.touches[0].clientY - offset.y
        }

        if(moveWindow===true){
            mainWindow.style.transform = `translate3d(${newPosX}px,${newPosY}px,0)`
        }
    }

    useEffect(()=>{
        window.addEventListener('touchmove',handleWindowMove)
        window.addEventListener('mousemove',handleWindowMove)
        return ()=> {
            window.removeEventListener('touchmove',handleWindowMove) 
            window.removeEventListener('mousemove',handleWindowMove)
        }
    },[moveWindow])

    return(
        <ContainerDiv ref={mainWindowRef} minimize={minimize} onClick={()=>pushZStack(id)}>
            <Header
                onPointerDown={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onPointerUp={() => {
                    toggleWindow(false);
                }}
                onTouchMove={(e) => {
                    toggleWindow(true);
                    onClickOffset(e);
                }}
                onTouchEnd={() => {
                    toggleWindow(false);
                }}
                >
                <span className="header"> <img src={internetPDFIcon} alt="pc icon" /> My Resume </span>
                <button onClick={()=>appendToMinimize(id)}><img src={minIcon} alt="minimize icon"/></button>
                <button onClick={()=>toggleWindowSize(minimize)}><img src={maxIcon} alt="maximize icon"/></button>
                <button onClick={()=>deactivateTask(id)}><img src={closeIcon} alt="close icon"/></button>
            </Header>
            <SubHeader>
                <Divider/>
                <LinkItem>Home</LinkItem>
                <LinkItem>Open</LinkItem>
                <LinkItem>File</LinkItem>
                <LinkItem>Settings</LinkItem>
                <LogoDiv>
                    <img src={logo} alt="logo"/>
                </LogoDiv>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <LinkItem className='back' onClick={()=>backPress(id)}> <img src={backIcon} alt="back icon"/> back</LinkItem>
            </SubHeader>
            <SubHeader>
                <Divider/>
                <span className="subHeader">Address</span>
                <Address>
                    <span className="address">c://Desktop/My Resume.pdf</span>
                </Address>
            </SubHeader>
            <Content>
                <Resume>
                    <ResumeHeader>
                        <h2>Franklin Barto</h2>
                        <h2>Software Developer</h2>
                    </ResumeHeader>
                    <Section1>
                        <img src={frank} alt="Franklin Barto portrait" />
                        <div>
                            <h2>Franklin</h2>
                            <h2>Barto</h2>
                            <span><b>Software Developer</b></span>
                            <p>A passionate software developer with a strong drive for developing innovative and efficient solutions. <br/><br/> This is a summary of my Resume</p>
                            <span>Happy to connect via <a href="mail:hello@franklinbarto.com"><b>hello@franklinbarto.com</b></a></span>
                        </div>
                    </Section1>
                    <Section2>
                        <div>
                            <h2><b>KENYA SPACE AGENCY</b> | Geo-Information and Data Management officer</h2>
                            <span>June 2023 - ongoing | Nairobi, KE</span>
                            <ul>
                                <li>
                                    Software development -  Developed several web-based solutions, four of which I led as a team lead. 
                                </li>
                                <li>
                                    Solutions Architect - Ensure optimal operation of onsite servers and management of virtualized infrastructure on both onsite and cloud based solutions.
                                </li>
                                <li>
                                    Data Engineering - Create automated data pipelines, extract, transform and load data in preparations for AI/ML and data analysis.
                                </li>
                                <li>
                                    Continuous Integrations/ Continuous Development - Codebase management/ Version Control (Git), Virtualizations/ Containerization, Testing.
                                </li>
                                <li>
                                    Document Drafting - ICT Policy and Strategy, Communications policy and strategy, Kenya Space Sector Policy and Bill, Mental health and Non communicable Diseases workplace policy.  
                                </li>
                            </ul>

                            <h2><b>KENYA SPACE AGENCY</b> | Software Development Intern</h2>
                            <span>June 2022 - june 2023 | Nairobi, KE</span>
                            <ul>
                                <li>
                                    Conducted a 12 week long computer science workshop within the workplace to transfer knowledge to other interns.
                                </li>
                                <li>
                                    Graphic design for posters, banners, business cards etc.    
                                </li>
                                <li>
                                    Embedded computer programming for satellites and weather stations.
                                </li>
                                <li>
                                    Appointed secretary on the Space Economy committee tasked with drafting strategies for the organization to make a positive economic impact.
                                </li>
                                <li>
                                    Full Stack web development, maintenance and management.
                                </li>
                            </ul>

                            <h2><b>Digitize Africa Online</b> | Freelance Web Development</h2>
                            <span>June 2022 - ongoing | Nairobi, KE</span>
                            <ul>
                                <li>
                                    Server Administration - management of main server as well as client servers.
                                </li>
                                <li>
                                    Business Development - handling client and customer engagement.
                                </li>
                                <li>
                                    Full-stack developer - Wrote and reviewed code using javascript, php.    
                                </li>
                                <li>
                                    Graphic Design - Creation of the digital assets to be used in the marketing material as well as the client websites.    
                                </li>
                            </ul>

                            <h2><b>Jamii Telecommunications LTD</b> | ICT Intern</h2>
                            <span>Jan 2019 - April 2019 | Nairobi, KE</span>
                            <ul>
                                <li>
                                    Network diagram mapping of the Nakuru CBD.    
                                </li>
                                <li>
                                    Creating excel sheets to present the staff service delivery efficiency.    
                                </li>
                                <li>
                                    Monitoring and troubleshooting network nodes mainly though remote access (SSH) and physical site visits.    
                                </li>
                            </ul>

                            <h2><b>Kenya National Library Volunteer</b> | Volunteer</h2>
                            <span>Jan 2018 - Mar 2018 | Nairobi, KE</span>
                            <ul>
                                <li>
                                    Teaching children in outreach    
                                </li>
                                <li>
                                    Carrying out library Orientation for new members     
                                </li>
                                <li>
                                    Facilitating reading sessions on premises during school visits.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Education</h2>
                            <span>University of East London</span>
                            <p>
                                MSc. Computer Science<br/>
                            </p>
                            <span>Strathmore University</span>
                            <p>
                                BSc. Telecommunications<br/>
                            </p>

                            <h2>Certification</h2>


                            <span>Introduction to Artificial Intelligence with Python</span>
                            <p>
                                Harvard/EDx | 2023
                            </p>

                            <span>AWS Solutions Architect - Associate</span>
                            <p>
                                AWS | 2023
                            </p>

                            <span>Digital Transformation</span>
                            <p>
                                InnoPharma, Ireland | 2022
                            </p>

                            <span>Figma UI/UX Design Essentials</span>
                            <p>
                                Udemy | 2022
                            </p>

                            <span>Introduction to Programming</span>
                            <p>
                                Harvard/EDx | 2021
                            </p>

                            <span>Computer Programming</span>
                            <p>
                                Institute of Advanced Technology | 2018
                            </p>

                            <h2>Technical Skills</h2>
                                <p> <b>Programming </b> <br/> 
                                Python, Javascript/Typescript
                                <br/>
                                <b>Database</b> <br/> 
                                Postgres, Mysql, Mondodb, OpenDataCube
                                <br/>
                                <b>DevOps</b> <br/> 
                                AWS, Azure, Git, Docker, Linux, VmWare, KeyCloak
                                <br/>
                                <b>Product Design</b> <br/> 
                                Figma, Blender
                                <br/>
                                <b>CMS</b> <br/> 
                                Drupal, Wagtail
                                <br/>
                            </p>

                        </div>
                    </Section2>
	    	    <ResumeFooter>
                        <span>based in nairobi, Ke</span>
                        <span>last updated on 13th October 2023</span>
                    </ResumeFooter>
                </Resume>
	    </Content>
            <ContentFooter>
                <div><span>3 Object(s)</span></div>
                <div><img src={internetPDFIcon} alt="icon"/> My Resume</div>
            </ContentFooter>
        </ContainerDiv>
    )
}

export default ResumeExplorer
